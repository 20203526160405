import {useState, useEffect} from 'react';
import moment from 'moment';
import Countdown from 'react-countdown';
import { useStoreState, useStoreActions} from "easy-peasy";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import loadingImg from '../imgs/loading.gif'
// import config from '../config.json'

import {
    makeStyles,
    Container,
    Card,
    CardHeader,
    CardContent,
    CardMedia,
    Button,
} from '@material-ui/core';
import MainError from '../components/MainError';
import MainAuctionCard from '../components/MainAuctionCard';
import { logout } from '../ApiControllers/api';

const useStyles = makeStyles((theme) => ({
    main:{
        margin:'auto',
    },
    buttonsTopBar:{
        display:'flex',
        marginTop:'15px', 
        width:'100%',
        // height:'50px',
        marginBottom:'8px'
    },
    CardsDiv:{
        // display:'table',
        margin:'auto',
        paddingBottom:'60px'
    },
    auctionCard:{
        backgroundColor:'lightgray',
        padding:'8px',
        maxWidth:'422px',
        maxHeight:'422px',
        width:'60%',
        height:'150px',
        marginTop:'28px',
        marginBottom:'28px',
        margin:'auto',
        paddingBottom:'20px'
    },
    cardCounterOLD:{
        marginTop:'8px',
        fontSize:'24px',
        letterSpacing:'0.235em'
    },
    isVerifiedButton:{
        width:'60%',
        backgroundColor:'#098E1F',
        margin:'auto',
        height:'60px',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        color:'white',
        fontWeight:'bold',
        fontSize:'20px',
        marginBottom:'25px',
        borderRadius:'10px',
        userSelect:'none',
        cursor:'pointer',

        '&:hover':{
            backgroundColor:'#2a7e38'
        }
    }
}))


const MainPage = () => {
    const myStyles = useStyles();
    const urlHistory = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [errorMsg, seterrorMsg] = useState("");

    const auctionsList = useStoreState(state=> state.auctionsList)
    const canHost      = useStoreState(state=> state.canHost)
    const isVerified   = useStoreState(state=> state.isVerified)

    const getAuctionsList = useStoreActions(actions => actions.getAuctionsList);
    

    
    
    useEffect(() => {
        getAuctionsList().then((status) => {
            setIsLoading(false)
            if(status == false)  {seterrorMsg("Error")} else {seterrorMsg("")}
           
        })
    },[])

    if(!isLoading && errorMsg == ""){
        return(
            <div>
                <div className={myStyles.main}>
                    
                    { localStorage.getItem("token") ? <span style={{userSelect:'none', fontSize:'20px'}} onClick={() => logout()}>{localStorage.getItem("username")} - {localStorage.getItem("token")} logout</span> :  
                        <div style={{userSelect:'none', fontSize:'20px'}} onClick={() => urlHistory.push(`/login`)}>
                            login
                        </div>}
                    <div className={myStyles.buttonsTopBar}>
                        {/* {canHost ?
                            <Button variant="contained" color="secondary" style={{fontSize:'19px'}} onClick={() => console.log("new auction page")}>مزاد جديد</Button>
                        :
                            ""
                        } */}

                        
                    </div>
                    
                    {isVerified != null && isVerified != true ? 
                        <div className={myStyles.isVerifiedButton} onClick={() => urlHistory.push(`/verify`)}>
                            isVerified == false
                        </div>
                    :
                        ""
                    }

                    {auctionsList.length > 0 ? 
                        <div className={myStyles.CardsDiv}>
                            {auctionsList.map(auction => 
                                <MainAuctionCard key={auction.unique_id} obj={auction}/>
                            )}
                        </div>

                    :
                        <div>
                            لايوجد مزاد
                        </div>
                    }


                    {/* {auctionsList.length > 0 ? 
                        <div className={myStyles.CardsDiv}>
                            {auctionsList.map(auction => 
                                <Card className={myStyles.auctionCard} onClick={() => streamUrlHandler(auction.unique_id)} key={auction.unique_id}>
                                    <CardHeader title={auction.auctionName}/>

                                    {auction.auctionImgs?.length > 0 ?
                                        <CardMedia component="img"  image={'http://fahadpc.local:83' + auction.auctionImgs[0].imgUrl}/>
                                    :
                                        <p>No img</p>
                                    }


                                    <CardContent>
                                        <div className={myStyles.cardCounter}>
                                            <CardCounter startDate={auction.auctionStartDate} endDate={auction.auctionEndDate}/>
                                        </div>
                                    </CardContent>
                                </Card>
                            )}
                            <br/>
                            <br/>
                            <br/>
                        </div>

                    :
                        <div>
                            لايوجد مزاد
                        </div>
                    } */}
                </div>
            </div>
        )
    }
    else if(!isLoading && errorMsg.length > 0){
        return(
            <div style={{width:'100%', display:'flex', justifyContent:'center', height:'100vh', alignItems:'center'}}>
                <MainError msg="Error"/>
            </div>
        )
    }
    else {
        return(
            <div style={{width:'100%', display:'flex', justifyContent:'center', alignSelf:'center', height:'100vh', alignItems:'center'}}>
                <img src={loadingImg} style={{ width:'200px', height:'200px'}}/> 
            </div>
        )
    }
}
export default MainPage;