import {useState} from 'react'
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import config from '../config.json'
import {
    makeStyles,
    Container,
    TextField,
    Button,
} from '@material-ui/core';
import { login } from '../ApiControllers/api';

const useStyles = makeStyles(() => ({
    formBox:{
        position:'absolute',
        top: '50%',
        left:'50%',
        WebkitTransform:'translateX(-50%) translateY(-50%',
        MozTransformStyle:'translateX(-50%) translateY(-50%)',
        transform:'translateX(-50%) translateY(-50%)',
        width:'80%',
        // backgroundColor:'lightgray',
    },
    inputBox:{
        marginTop:'405px'
    },
    inputLable:{
        textAlign:'right'
    }
}))

const get_user_info_requst = async (token) => {
    
    const url = `${config.server_url}/auth/users/me/`;
    const get_user_info_opt = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' ,
        'Authorization':`Token ${token}`,
        },
    };
    const req  = await fetch(url, get_user_info_opt)
    const body = await req.json()

    if(req.status === 200){
        return body.username
    }
    else{
        toast.error(`error ${body}`)
        return ''
    }
    
}

async function gettockenOLD(data) {
    const tokenUrl = `${config.server_url}/auth/token/login`;
    

    const token_request_opt = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            username: data.username,
            password: data.password
        })
    };

    

    const res = await fetch(tokenUrl, token_request_opt)
    const body = await res.json()
    if(res.status === 200){
        localStorage.setItem('token', body.auth_token)

        get_user_info_requst(body.auth_token).then(username =>{
            localStorage.setItem('username', username)
        })
        return true
    }
    else{
        return false
    }
}

const LoginPage = () =>{
    const myStyles = useStyles();
    const { register, handleSubmit, watch, errors } = useForm();
    let history = useHistory();
    
    const [errorMsg, SetErrorMsg] = useState("")
    const [buttonStatus, setButtonStatus] = useState(false)
    

    const onSubmit = data => {
        setButtonStatus(true)
        SetErrorMsg("")
        
        if(data.username.length > 0 && data.password.length){
            // setIsLoading(true)

            login(data.username, data.password).then((status) =>{
                if(!status.error)
                {
                    history.push('/')
                }
                else {
                    SetErrorMsg("تاكد من اسم المستخدم او كلمة السر")
                }
            })
        }
        else{
            SetErrorMsg("تاكد من اسم المستخدم او كلمة السر")
        }
        setButtonStatus(false)
        
    }

    return(
        <div className={myStyles.formBox}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Container>

                    <div className={myStyles.inputBox}>
                        <div className={myStyles.inputLable} style={{textAlign:'right', fontSize:'18px'}}>
                            اسم المستخدم
                        </div>
                        <div className={myStyles.input} style={{marginTop:'-8px'}}>
                            <TextField
                            name="username" 
                            inputRef={register}
                            margin="dense" 
                            variant="outlined"
                            type="text" 
                            fullWidth 
                            margin="normal"  
                            inputProps={{min: 0, style: { textAlign: 'right'}}}
                            />
                        </div>
                    </div>


                    <div className={myStyles.inputBox} style={{marginTop:'25px'}}>
                        <div className={myStyles.inputLable} style={{textAlign:'right', fontSize:'18px'}}>
                            كلمة السر
                        </div>
                        <div className={myStyles.input} style={{marginTop:'-8px'}}>
                            <TextField
                            name="password" 
                            inputRef={register}
                            margin="dense" 
                            variant="outlined"
                            type="password" 
                            fullWidth 
                            margin="normal"  
                            inputProps={{min: 0, style: { textAlign: 'right'}}}
                            />
                        </div>
                    </div>

                    <div className={myStyles.errosBox} style={{textAlign:'right', fontSize:'18px', color:'red', fontWeight:'bold'}}>
                        {errorMsg.length > 0 ? errorMsg : ""}
                    </div>


                    <div className={myStyles.formButton} type="submit" style={{marginTop:'25px'}}>
                        <Button disabled={buttonStatus} variant="contained" size="large" color="primary" type="submit" >تسجيل الدخول</Button>
                    </div>
                </Container>
            </form>
        </div>
    )
}

export default LoginPage