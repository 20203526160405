import {useState, useEffect} from 'react';
import { useStoreState, useStoreActions} from "easy-peasy";
import { useHistory, useParams} from "react-router-dom";
import { toast } from 'react-toastify';
import loadingImg from '../imgs/loading.gif'
import {
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    main:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        fontSize:'66px',
        fontWeight:'bolder'
    },
}))

const MainError = (props) => {
    const myStyles = useStyles();
    
    return(
        <div className={myStyles.main}>
            {props.msg}
        </div>
    )

}

export default MainError;