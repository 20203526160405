import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import {StoreProvider, createStore} from 'easy-peasy';
import { ToastContainer } from 'react-toastify';
import {default as MyModel} from './models/mainModel';


import LiveStramPage from'./pages/LiveStreamPage'
import LoginPage from './pages/LoginPage';
import MainPage from './pages/MainPage';

import {
  makeStyles,
  
} from '@material-ui/core';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch
} from "react-router-dom";
import VerifiyPhoneNumberPage from './pages/VerifiyPhoneNumberPage';
import SignupPage from './pages/SignupPage';


const useStyles = makeStyles(() => ({
  liveStramPage:{
    // paddingTop:"25px"
  }
})
)
const store = createStore(MyModel)
function App() {
  const myStyles = useStyles();
  return (
    <div className="App">
      <StoreProvider store={store}>
        <Router>
          {/* HEADER HERE */}
          <ToastContainer rtl/>
          <Switch >
            <Route path="/verify">
                <VerifiyPhoneNumberPage />
            </Route>
            <Route path="/signup">
                <SignupPage />
            </Route>
            <Route path="/stream/id=:id">
                <div className={myStyles.liveStramPage}>
                  <LiveStramPage  />
                </div>
            </Route>
            <Route path="/login">
                <LoginPage/>
            </Route>
            <Route path="/">
                <MainPage/>
            </Route>
          </Switch>
        </Router>
      </StoreProvider>
    </div>
  );
}

export default App;
