

const getToken = () => (localStorage.getItem('token'))

const getRequestOptions = () => {
    return({
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization':`Token ${getToken()}`,
        }
    })
}

const postWithoutRequestOptions = () => {
    // * a work around to avoid the server caching 
    return({
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization':`Token ${getToken()}`,
        },
    })
}

const postRequestOptions = (body) => {
    return({
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization':`Token ${getToken()}`,
        },
        body: JSON.stringify(body)
    })
}


module.exports = {
    getRequestOptions, 
    getToken,
    postRequestOptions,
    postWithoutRequestOptions
};