// SignupPage.jsx

import {useState, useEffect} from 'react';
import { useStoreState, useStoreActions} from "easy-peasy";
import { useHistory, useParams} from "react-router-dom";
import { toast } from 'react-toastify';
import loadingImg from '../imgs/loading.gif'
// import DoneIcon from '@material-ui/icons/Done';

import MainError from '../components/MainError';

import {
    Input,
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    mainO:{
        position:'absolute',
        top: '50%',
        left:'50%',
        WebkitTransform:'translateX(-50%) translateY(-50%',
        MozTransformStyle:'translateX(-50%) translateY(-50%)',
        transform:'translateX(-50%) translateY(-50%)',
        width:'80%',
    },
    InputSignupDiv:{
        marginTop:'80px',
        paddingTop:'80px',
        'width':'100%',
        'backgroundColor':'red',
    },
    InputLableEle:{
        'textAlign':'right',
        color:'red'
    }
}))

const SignupPage = () => {
    const myStyles = useStyles();
    const urlHistory = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, seterrorMsg] = useState("");


    // const getState = useStoreState(state => state.ShowWrongOnlyState)
    // const useFunc = useStoreActions(actions => actions.submitReadingSolution);
    
    useEffect(() => {
        
    },[])

    if(!isLoading && errorMsg == ""){
        return(
            <div className={myStyles.main}>

                <div className={myStyles.InputSignupDiv}>
                    <div className={myStyles.InputLableEle} style={{textAlign:'right'}}>
                        الاسم
                    </div>
                    <Input 
                        fullWidth 
                        dir="rtl" 
                        type="text"
                    />
                </div>

                <div className={myStyles.InputSignupDiv}>
                    <div className={myStyles.InputLableEle} style={{textAlign:'right'}}>
                        الايميل
                    </div>
                    <Input 
                        fullWidth 
                        dir="rtl" 
                        type="text"
                    />
                </div>

                <div className={myStyles.InputDiv}>
                    <div className={myStyles.InputLableEle} style={{textAlign:'right'}}>
                        كلمة السر
                    </div>
                    <Input 
                        fullWidth 
                        dir="rtl" 
                        type="text"
                    />
                </div>
            </div>
        )
    }
    else if(!isLoading && errorMsg.length > 0){
        return(
            <div style={{width:'100%', display:'flex', justifyContent:'center', height:'100vh', alignItems:'center'}}>
                <MainError msg="Error"/>
            </div>
        )
    }
    else {
        return(
            <div style={{width:'100%', display:'flex', justifyContent:'center', alignSelf:'center', height:'100vh', alignItems:'center'}}>
                <img src={loadingImg} style={{ width:'200px', height:'200px'}}/> 
            </div>
        )
    }

}

export default SignupPage;