import config from '../config.json'


export const getServerIp = () => {
    return config.isLiveServer ? config.server_url : config.server_url_local
}

export const  CCN_getUserToken = () => {
    const token = localStorage.getItem('token')

    return token
}

export const CCN_setUserInfo = (authObj) => {
    if(authObj != null){
        localStorage.setItem('email', authObj.email) 
        localStorage.setItem('id', authObj.id) 
        localStorage.setItem('username', authObj.username) 
    }
    else{
        localStorage.removeItem("email")
        localStorage.removeItem("id")
        localStorage.removeItem("username")
    }
}

export const CCN_getUserInfo = () => {
    return{
        "email"    : localStorage.getItem("email"),
        "id"       : localStorage.getItem("id"),
        "username" : localStorage.getItem("username")
    }
}

export const CCN_setUserToken = (token) => {
    if(token != null){
        localStorage.setItem("token", token)
    }
    else{
        localStorage.removeItem("token")
    }
}

export const httpGetHeadObject = (token=null) => {
    if(token != null){
        return({
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            }
        })
    }
    else{
        return({
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            }
        })
    }
}

export const CCN_httpPostHeadObject = (body, token=null) => {
    if(token != null){
        return {
            'method': 'POST',
            'headers': { 'Content-Type': 'application/json' },
            'body': JSON.stringify(body),
            'Authorization': `Token ${token}`,
        }
    }
    else{
        return {
            'method': 'POST',
            'headers': { 'Content-Type': 'application/json' },
            'body': JSON.stringify(body)
        }
    }
}