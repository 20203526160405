import Countdown from 'react-countdown';
import moment from 'moment';

const AuctionCounter = (props) => {
    const now       = moment()
    const startDate = moment(props.startDate)
    const endDate   = moment(props.endDate)

    const cardCounterStyle = {
        marginTop:'8px',
        fontSize:'24px',
        letterSpacing:'0.235em'
    }

    const counterRenderer = ({ days, hours, minutes, seconds, completed}) => {
        if (completed) {
          return <span style={{color:'red', fontWeight:'bolder'}} >مباشر</span>;
        } else {
          return(
              <div style={{marginTop:'18px'}}>
                  {days}:{hours}:{minutes}:{seconds}
              </div>
          );
        }
    }

    if(now.isBetween(startDate,endDate)){
        // * LIVE NOW
        return(
            <div style={{fontSize:'24px', fontWeight:'bolder', color:'red'}}>
                بدأ
            </div>
        )
    }
    else if(now.isBefore(startDate)){
        // * Show timer 
        return(
            <div style={cardCounterStyle}>
                <Countdown date={startDate} renderer={counterRenderer}/>
            </div>
        )
    }
    else if(now.isAfter(endDate)){
        // Strem Done
        return(
            <div style={{fontSize:'24px'}}>
                انتهى المزاد
            </div>
        )
    }
    else{
        return ""
    }
}

export default AuctionCounter;