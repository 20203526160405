import { toast } from 'react-toastify'
import config from '../config.json'
import { CCN_httpPostHeadObject, CCN_getUserToken, CCN_setUserInfo, CCN_setUserToken, getServerIp, httpGetHeadObject } from './apiHelper'

export const getUserInfo = async () => {
    const token = CCN_getUserToken()

    if (token != null){
        const url = getServerIp() + "/auth/users/me/"
        const header = httpGetHeadObject(token)

        const req = await fetch(url, header)
        const body = await req.json()

        if(req.status==200){
            CCN_setUserInfo(body) //Set User info object in localstorage
            return{
                "error":false,
                "data":body
            }
        }
        else if(req.status == 401){
            CCN_setUserToken(null)
            CCN_setUserInfo(null)
            return{ 
                'error':true,
                'req':req,
                'data':null
            }
        }
        else{
            return {
                'error':true,
                'req':req,
                'data':null
            }
        }
    }
    else{
        return{
            "error":true,
            "data":null
        }
    }
    
}


export const login = async (username, passowrd) => {
    const url = getServerIp() + "/auth/token/login"
    const header = CCN_httpPostHeadObject({
        'username':username,
        'password':passowrd
    })

    const req  = await fetch(url, header)
    const body = await req.json()

    if(req.status==200){
        CCN_setUserToken(body.auth_token)
        const userInfoReq = await getUserInfo()

        if(!userInfoReq.error){
            return{
                "error":false,
                "data":body
            }
        }
        else{
            return {
                'error':true,
                'req':req,
                'data':null
            }
        }
    }
    else{
        console.log(`Error in login`);
        return {
            'error':true,
            'req':req,
            'data':null
        }
    }
}

export const logout = async () => {
    const token = CCN_getUserToken()

    if (token != null){
        const url = getServerIp() + "/auth/token/logout"
        const header = httpGetHeadObject(token)
        header.method = "POST"

        const req = await fetch(url, header)
        // const body = await req.json()

        if(req.status==204){
            // window.location.href("/")
            CCN_setUserToken(null)
            CCN_setUserInfo(null)
            window.location.reload();
            // setTimeout(() => {
            // }, 1000);
            return{
                "error":false,
                "data":true
            }
        }
        else if(req.status == 401){
            CCN_setUserToken(null)
            CCN_setUserInfo(null)
            return{ 
                'error':true,
                'req':req,
                'data':null
            }
        }
        else{
            return {
                'error':true,
                'req':req,
                'data':null
            }
        }
    }
    else{
        // ! User with no token should get the see the stream!
        return{
            "error":true,
            "data":null
        }
    }
}

export const getAgoraToken = async (id, simpleID) => {
    const token = CCN_getUserToken()

    if (token != null){
        const url = getServerIp() + `/api/v1/get/streamtoken/${id}/${simpleID}`
        const header = httpGetHeadObject(token)

        const req = await fetch(url, header)
        const body = await req.json()

        if(req.status==200){
            


            return{
                "error":false,
                "data":body
            }
        }
        else if(req.status == 401){
            CCN_setUserToken(null)
            CCN_setUserInfo(null)
            return{ 
                'error':true,
                'req':req,
                'data':null
            }
        }
        else{
            return {
                'error':true,
                'req':req,
                'data':null
            }
        }
    }
    else{
        // ! User with no token should get the see the stream!
        return{
            "error":true,
            "data":null
        }
    }
}

export const getAuctionList = async () => {
    const url = getServerIp()
    const header =httpGetHeadObject(CCN_getUserToken())
    
    const req  = await fetch(url + "/api/v1/get/auctionlist", header)
    const body = await req.json()

    

    if(req.status == 200){
        return{
            'error':false,
            'data':body
        }
    }
    else if(req.status == 401){
        CCN_setUserToken(null)
        CCN_setUserInfo(null)
        getAuctionList()
        console.log("reconnecting! user without token");
        // return{ 
        //     'error':true,
        //     'req':req,
        //     'data':null
        // }
    }
    else{
        return{
            'error':true,
            'req':req,
            'data':null
        }
    }
}

export const setUserEvent = async (id, status, chatType) => {
    const token = CCN_getUserToken()

    if (token != null){
        const url = getServerIp() + `/api/v1/get/viewscontroller/${id}/${status}`
        const header = httpGetHeadObject(token)

        const req = await fetch(url, header)
        const body = true //await req.json()

        if(req.status==200){
            


            return{
                "error":false,
                "data":body
            }
        }
        else if(req.status == 401){
            // CCN_setUserToken(null)
            // CCN_setUserInfo(null)
            return{ 
                'error':true,
                'req':req,
                'data':null
            }
        }
        else{
            
            return {
                'error':true,
                'req':req,
                'data':null
            }
        }
    }
    else{
        // ! User With No Token
        return{
            "error":true,
            "data":null
        }
    }
}

export const getAuctionEndpoint = async (id) => {
    const token = CCN_getUserToken()

    if (token != null){
        const url = getServerIp() + `/api/v1/get/mainendpoint/${id}`
        const header = httpGetHeadObject(token)

        const req = await fetch(url, header)
        const body = await req.json()

        if(req.status==200){
            


            return{
                "error":false,
                "data":body
            }
        }
        else if(req.status == 401){
            CCN_setUserToken(null)
            CCN_setUserInfo(null)
            return{ 
                'error':true,
                'req':req,
                'data':null
            }
        }
        else{
            
            return {
                'error':true,
                'req':req,
                'data':null
            }
        }
    }
    else{
        // ! User With No Token
        return{
            "error":true,
            "data":null
        }
    }
}

export const setNewComment = async (id, msg) => {
    const token = CCN_getUserToken()


    if (token != null){
        const url = getServerIp() + `/api/v1/post/newcomment/${id}/${msg}`
        const header = httpGetHeadObject(token)

        const req = await fetch(url, header)
        const body = await req.json()

        if(req.status==200){
            


            return{
                "error":false,
                "data":body
            }
        }
        else if(req.status == 401){
            // CCN_setUserToken(null)
            // CCN_setUserInfo(null)
            toast.error("401 error")
            return{ 
                'error':true,
                'req':req,
                'data':null
            }
        }
        else{
            
            return {
                'error':true,
                'req':req,
                'data':null
            }
        }
    }
    else{
        // ! User With No Token
        return{
            "error":true,
            "data":null
        }
    }
}

export const deleteMassage = async (id, msgID) => {
    const token = CCN_getUserToken()


    if (token != null){
        const url = getServerIp() + `/api/v1/delete/chatmsg/${id}/${msgID}`
        const header = httpGetHeadObject(token)

        const req = await fetch(url, header)
        const body = await req.json()

        if(req.status==200){
            


            return{
                "error":false,
                "data":body
            }
        }
        else if(req.status == 401){
            // CCN_setUserToken(null)
            // CCN_setUserInfo(null)
            toast.error("401 error")
            return{ 
                'error':true,
                'req':req,
                'data':null
            }
        }
        else{
            
            return {
                'error':true,
                'req':req,
                'data':null
            }
        }
    }
    else{
        // ! User With No Token
        return{
            "error":true,
            "data":null
        }
    }
}