// firebaseConfig.json


const firebaseConfig = {
    apiKey: "AIzaSyBz1-Av9PO6RpL6HFP-_JMbYcH3JWnKA1k",
    authDomain: "chat-7cc89.firebaseapp.com",
    databaseURL: "https://chat-7cc89-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "chat-7cc89",
    storageBucket: "chat-7cc89.appspot.com",
    messagingSenderId: "565610809430",
    appId: "1:565610809430:web:9d684481d34a835ad453f7",
    measurementId: "G-MV9NK2EDSM"
}


export default firebaseConfig