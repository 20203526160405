// MainAuctionCard.jsx
import {useState, useEffect} from 'react';
import { useStoreState, useStoreActions} from "easy-peasy";
import { useHistory, useParams} from "react-router-dom";
import { toast } from 'react-toastify';
import loadingImg from '../imgs/loading.gif'
import MainError from '../components/MainError';
import moment from 'moment';

import {
    CardActionArea,
    makeStyles,
    Typography,
    Card
} from '@material-ui/core';
import AuctionCounter from './AuctionCounter';
import { getServerIp } from '../ApiControllers/apiHelper';
import { height } from 'dom-helpers';

const useStyles = makeStyles(() => ({
    main:{
        minWidth:'350px',
        width:'60%',
        margin:'auto',
        marginBottom:'30px',
        height:'500px',
        backgroundColor:'#C4C4C4',
        borderRadius:'10px',
        display:'flex',
        flexDirection:'column',
    },
    cardHeader:{
        flexBasis:'13%',
        marginTop:'30px',
    },
    cardTitle:{
        fontSize:'28px'
    },
    cardDate:{
        fontSize:'18px'
    },
    cardCounter:{
        marginTop:'8px',
        marginBottom:'10px'
    },
    cardImageSlides:{
        flexGrow:1,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:'black',
        color:'white',
        width:'80%',
        margin:'auto'
    },
    cardImg:{

    },
    cardNoImageText:{
        fontSize:'30px'
    },
    detailsCardButton:{
        width:'80%',
        height:'60px',
        backgroundColor:'#098E1F',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        fontSize:'25px',
        color:'white',
        fontWeight:'bolder',
        margin:'auto',
        userSelect:'none',
        cursor:'pointer',
        marginBottom:'5%',
        marginTop:'10px'
    }
}))

const MainAuctionCard = (props) => {
    const myStyles = useStyles();
    const urlHistory = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, seterrorMsg] = useState("");
    const obj = props.obj

    const streamUrlHandler = (id) => urlHistory.push(`/stream/id=${id}`)
    // const getState = useStoreState(state => state.ShowWrongOnlyState)
    // const useFunc = useStoreActions(actions => actions.submitReadingSolution);

    useEffect(() => {
        
    },[])

    if(!isLoading && errorMsg == ""){
        return(
            <div className={myStyles.main} >
                <div className={myStyles.cardHeader}>
                    <div className={myStyles.cardTitle}>
                        {obj.auctionName}
                    </div>
                    <div className={myStyles.cardDate}>
                        {moment(obj.auctionStartDate).format("DD/MM/YYYY hh:mm A")}
                    </div>
                </div>

                <div className={myStyles.cardCounter}>
                    <AuctionCounter startDate={obj.auctionStartDate} endDate={obj.auctionEndDate}/>
                </div>

                <div className={myStyles.cardImageSlides}>
                    {obj.auctionImgs.length > 0 ? 
                        <img className={myStyles.cardImg} src={getServerIp() + obj.auctionImgs[0].imgUrl} alt="loading..." />
                        :
                        <div className={myStyles.cardNoImageText}>
                            لا توجد صور
                        </div>
                    }
                </div>

                <div className={myStyles.detailsCardButton} onClick={() => streamUrlHandler(obj.unique_id)}>
                    التفاصيل
                </div>
            </div>
        )
    }
    else if(!isLoading && errorMsg.length > 0){
        return(
            <div style={{width:'100%', display:'flex', justifyContent:'center', height:'100vh', alignItems:'center'}}>
                <MainError msg="Error"/>
            </div>
        )
    }
    else {
        return(
            <div style={{width:'100%', display:'flex', justifyContent:'center', alignSelf:'center', height:'100vh', alignItems:'center'}}>
                <img src={loadingImg} style={{ width:'200px', height:'200px'}}/> 
            </div>
        )
    }

}

export default MainAuctionCard;