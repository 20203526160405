import '../css/LiveStreamPage.css';
import {useState, useEffect, useCallback} from 'react';
import { useRef } from 'react'
import UserMsg from '../components/UserMsg'
import Div100vh from 'react-div-100vh'
import config from '../config.json' 
import RequestTools from '../utils/RequestTools'
import moment from 'moment'
import { useStoreState, useStoreActions} from "easy-peasy";
import axios from 'axios';
import DetectRTC from 'detectrtc'
import useAgora from '../hooks/useAgora.jsx'
import AgoraRTC from 'agora-rtc-sdk-ng';
import firebase from 'firebase/app'
import 'firebase/database'
import firebaseConfig from '../firebaseConfig'


import {
    useParams,
    useHistory
} from "react-router-dom";
import {
    makeStyles,
    List,
    Grid,
    Button,
    Input,
    Menu,
    MenuItem
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { deleteMassage, getAgoraToken, getAuctionEndpoint, setNewComment, setUserEvent } from '../ApiControllers/api';
import { Gathering } from '../Cont/Gathering';

const useStyles = makeStyles((theme) => ({
    main:{
        height: "100vh", 
        overflow:"hidden"
    },
    vidoContaner:{
        margin:"0 auto",
        width:"95%",
        height:"320px",
        // backgroundColor:"black",
    },
    vid:{
        objectFit: "cover"
    },
    comments:{
        height: "30vh",
    },
    commentsList:{
        marginRight:"15px",
        marginLeft:"15px",
        height:"50vh",
        overflow: 'scroll',
        msOverflowStyle: "none",
        scrollbarWidth: "none",

        '&::-webkit-scrollbar': {
            width: '0.01em'
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.1)',
          outline: '1px solid slategrey'
        }
    },
    botoomColmn:{
        position: 'fixed',
        bottom: "0px",
        width:"100%",
        columnWidth: "100%",
        height:'40px'
    },
    ChatSendBox:{
        display:'flex',
        width:"100%",
        backgroundColor:"lightGray",
    },
    MsgInputDiv:{
        width:"82%",
        height:"100%",
        textAlign:"right",
        paddingTop:"5px"
    },
    sendButton:{
        width:"15%"
    },
    TopBar:{
        position: 'fixed',
        top:0,
        width:'100%',
        backgroundColor:'#ffffff00',
        zIndex:1
    },
    TopBarGrid:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-around'
    },
    TopBarGridButton:{
        borderRadius    :   '50%',
        border          :   'none',
        color           :   'white',
        padding         :   '20px',
        textAlign       :   'center',
        textDecoration  :   'none',
        margin          :   '4px 2px'
    }
}))



const client = AgoraRTC.createClient({ codec: 'h264', mode: 'live' });

const LiveStramPage = () => {
    const myStyles       = useStyles();
    const urlHistory     = useHistory();
    const ws             = useRef(null);
    const messagesEndRef = useRef(null)
    const {id}           = useParams();
    const simpleID       = id.toString().split('-').slice(-1).pop(); 

    const agora_cameraTrack  = useRef(null)
    const agora_microphoneTrack  = useRef(null)
    var   aogra_cameraIndex = 0;
    var aogra_cameraID = ""
    var aogra_Allcamera = []

    const [auctiondetails     , setAuctiondetails    ] = useState(null)
    const [viewersCount       , setViewersCount      ] = useState(0)
    const [massageBox         , setMassageBox        ] = useState("")
    const [muteButton         , setMuteButton        ] = useState(true)
    const [startButton        , setStartButton       ] = useState(false)
    const [testingButton      , setTestingButton     ] = useState(false)
    const [firebase_database  , setFirebase_database ] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorlist, setAnchorlist] = useState([])


    const [, updateState] = useState();
    const forceUpdate     = useCallback(() => updateState({}), []);


    const chat              = useStoreState(state => state.chat)
    const userRules         = useStoreState(state => state.userRules)
    const setChatList       = useStoreActions(actions => actions.setChatList)
    const setUserRules      = useStoreActions(actions => actions.setUserRules)
    const addOneToChatList  = useStoreActions(actions => actions.addOneToChatList)
    const deleteChatMsg     = useStoreActions(actions => actions.deleteChatMsg)
    

    
    const scrollToBottom = () => messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    const isLoggedInUserCanDeleteMassages = () => [userRules?.isCord, userRules?.isHost].some(item => item);
    const updateUsersCount = (obj) => setViewersCount(obj.users_count)
    // const randomName = () => Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)


    const wsmanager =  () => {

        // use the ref 
        ws.current = new WebSocket(config.websocket_url)
        
        // const uws = new WebSocket(config.websocket_url)
        
        
        ws.current.onopen = () => {
            const onOpenMsg = {
                "type"     : "SUB_TO_ROOM",
                "ROOM_ID"  :  id
            }

            // to json string
            const str = JSON.stringify(onOpenMsg)
            
            // Encoder object 
            var encoder = new TextEncoder();

            // Encoded msg to binery 
            const encodedMsg = encoder.encode(str)

            // Send encoded msg to websocket server 
            ws.current.send(encodedMsg)
        }
        
        ws.current.onmessage = (e) => {
            if(e.data === ''){
                ws.current.send('')
            }
            else{
                try {
                    const msg = JSON.parse(e.data)
                    switch (msg.type) {
                        case "update_users_count":
                            updateUsersCount(msg)
                            break;
                        case "NEW_COMMENT":
                            addOneToChatList(msg)
                            scrollToBottom()
                            break;
                        case "MASSAGE_DELETED":
                            deleteChatMsg(msg)
                            break;
                        default:
                            break;
                    }
                } catch (error) {
                    toast.error(`error`)
                    console.log(`error ${error}`) 
                }
            }
        }
    }

    String.prototype.cycle = (arr)  => {
        const i = arr.indexOf(this.toString())
        if (i === -1) return undefined
        return arr[(i + 1) % arr.length];
    }

    // const nextCamera = () => {
    //     cycle()
    // }
    
    const handleHttpRequests = async () => {
        const auctionEndpoint = await getAuctionEndpoint(id)


        if(!auctionEndpoint.error){
            setChatList(auctionEndpoint.data.last10Msgs.massages)
            setUserRules(auctionEndpoint.data.userRules)
            setAuctiondetails(auctionEndpoint.data.auctionDetails)
            // forceUpdate()
            auctionEndpoint.data.chatType == "LOCAL" ? wsmanager() : realtimeDBController()
            console.log(auctionEndpoint.data.userRules?.isHost);
            setTimeout(() => {
                console.log('status', userRules);
                console.log(auctionEndpoint.data.userRules);
                // if(!auctionEndpoint.data.userRules?.isHost) {stremController()}
            }, 1000);
            // setUserEvent(id ,1, "GOOGLE")

            setAnchorlist( await AgoraRTC.getCameras())
        }
    }

    const realtimeDBController = async () => {
        try {
            firebase.initializeApp(firebaseConfig)
            
            var db = firebase.database()
            
            const dbRef = db.ref(`/${id}/msgs`)
            const presenceRef = db.ref(`/${id}/presence`)
            
           
            setFirebase_database(dbRef) 

            setChatList(await dbRef.get())

            // Works with list bug
            // presenceRef.on('value', (snap) => {
            //     if(snap.val()){
            //         presenceRef.onDisconnect().remove()
            //         presenceRef.set("USEr")
            //     }
            // })

            dbRef.on('value', snapshot => {
                if (snapshot.exists()) {
                    const newMsg = snapshot.val()
                    setChatList(newMsg)
                    // presenceRef.onDisconnect().remove() // Works with list bug
                    // presenceRef.set("USEr")             // Works with list bug
                }
            })

            var gath = new Gathering(db, id)

            gath.join(null, "Fahad")

            gath.onUpdated(function(count, users) {
                // console.log(gath.roomName + ' have '+ count +' members.');
                // console.log('Here is the updated users list -');
                var usersList = []
                for(var i in users) {
                    // console.log(users[i] + '(id: '+ i + ')');
                    usersList.push(users[i])
                }
                setViewersCount(usersList.length)
            });
        } 
        catch (error) {
            console.error(error);
            toast.error(error)
        }

    }

    
    useEffect(() => {
        handleHttpRequests()

        
    }, []);

    useEffect(() => {
        setStartButton(client.connectionState == "CONNECTED" ? false : true)
        console.log('start u[pdate');
    }, [client.connectionState])
    
    const debug = () => {
        console.log(client.connectionState)
        console.log('---------------')
        console.log(client.getRTCStats())
        console.log('---------------')
        console.log(aogra_Allcamera)
    }
    const stremController = async() => {
        if(userRules != null){
            if(userRules.isHost){
                // This the host
                
                if(!auctiondetails.isLocalRTC){
                    // How will be streamed (LocalRtc, AgoraRtc)
                    // use AgoraRtc
                    
                    // * [] get the token from servr with the correct end date
                    // * [x] request the access to the camera and mic
                    // * [-] manage how the mute button will work 
                    // * [] manage how the Stream button will work (stop stream and brodcast it by WS?) 
                    // * [x] stream the video and audio to agora
                    
                    // * -------------------------------------
                    
                    // Agora token 
                    const Agoratoken = await getAgoraToken(id, simpleID) // ! Handle error

                    // This is the host
                    client.setClientRole('host')

                    // ! app id from server 
                    const uid = await client.join("57348f54d94e471faddf4f22f90604b0", simpleID, Agoratoken.data.token); // ! useless

                    const deviceIDList = await AgoraRTC.getCameras()
                    const deviceCamaraID = deviceIDList[0].deviceId

                    aogra_cameraID = deviceCamaraID

                    // video feed
                    agora_cameraTrack.current = await AgoraRTC.createCameraVideoTrack({
                        cameraId:deviceCamaraID,
                        facingMode:"environment",
                        encoderConfig:"480p_1", // https://docs.agora.io/en/Video/API%20Reference/web/v4.2.1/globals.html#videoencoderconfigurationpreset
                    });

                    const tt = await AgoraRTC.createCameraVideoTrack({
                        cameraId:deviceCamaraID,
                        facingMode:"environment",
                        encoderConfig:"480p_1", // https://docs.agora.io/en/Video/API%20Reference/web/v4.2.1/globals.html#videoencoderconfigurationpreset
                    });

                    
                    
                    
                    // mic feed
                    agora_microphoneTrack.current = await AgoraRTC.createMicrophoneAudioTrack();
                    

                    // publish stream feeds to streaming service
                    await client.publish(agora_microphoneTrack.current)
                    await client.publish(agora_cameraTrack.current)

                    // play the stream 
                    agora_cameraTrack.current.play('remote-container')
                }
                else if(auctiondetails.isLocalRTC){
                    // use LocalRtc
                    
                    // // [] get the token from servr with the correct end date
                    // *  [] request the access to the camera and mic
                    // *  [] manage how the mute button will work 
                    // *  [] manage how the Stream button will work (stop stream and brodcast it by WS?) 
                    // *  [] call any one that joins with WS
                    
                    
                    
                }
                // error?
            }
            else if(!userRules.isHost){ 
                // this is the viewer
    
                // How will be streamed (LocalRtc, AgoraRtc)
                if(!auctiondetails.isLocalRTC){
                    // use AgoraRtc
                    
                    const Agoratoken = await getAgoraToken(id, simpleID)//.data.token // ! Handle error
                   
                    client.setClientRole('audience')
                    console.log('client', Agoratoken);
    
                    const uid = await client.join("57348f54d94e471faddf4f22f90604b0", simpleID, Agoratoken.data.token); // ! useless



                    client.on("user-published", async (user, mediaType) => {
                        // Initiate the subscription
                        await client.subscribe(user, mediaType);
                      
                        // If the subscribed track is an audio track
                        if (mediaType === "audio") {
                          const audioTrack = user.audioTrack;
                          // Play the audio
                          audioTrack.play();
                        } else {
                          const videoTrack = user.videoTrack;
                          // Play the video
                          videoTrack.play('remote-container');
                        }
                    });
                }
                else if(auctiondetails.isLocalRTC){
                    // use LocalRtc
                    
                    // // [] get the token from servr with the correct end date
                    // *  [] request the access to the camera and mic
                    // *  [] manage how the mute button will work 
                    // *  [] manage how the Stream button will work (stop stream and brodcast it by WS?) 
                    // *  [] call any one that joins with WS
                }
            }
            else{
                // no data about the user
            }
        }
        else {
            console.log('User role error', userRules);
            toast.error("User role error")
        }
    }

    const switchCameraButton = async (deviceId) => {
        // const deviceIDList = await AgoraRTC.getCameras()
        // var myList = []
        
        // for(var de in deviceIDList){
        //     myList.push(de.deviceId)
        // }
        // console.log(deviceIDList);
        // const newDevice = ddd.cycle(myList)
        // aogra_cameraID = newDevice
        // console.log(aogra_cameraID);
        // agora_cameraTrack.current.getVideoTrack().stop()
        // agora_cameraTrack.current.switchDevice("video",deviceId)

        agora_cameraTrack.current.getMediaStreamTrack().stop()
        agora_cameraTrack.current.setDevice(deviceId)
    }

    // const AgoraCameras = () => {
    //     // const ttttt = await AgoraRTC.getCameras()

    //     return(
    //         AgoraRTC.getCameras().then((x) => {
    //             x.toString()
    //         })
            
    //     )
    // }

    return(
        <div className={myStyles.main}>
            <Div100vh>
                <div>
                    {userRules?.isHost ? 
                        <div className={myStyles.TopBar}>
                            <div className={myStyles.TopBarGrid}>
                                <button className={myStyles.TopBarGridButton} style={{backgroundColor: muteButton    ? "red" : "green"}} onClick={() => setMuteButton(!muteButton)}>M</button>
                                <button className={myStyles.TopBarGridButton} style={{backgroundColor: startButton   ? "red" : "green"}} onClick={() => setStartButton(!startButton)}>S</button>
                                <button className={myStyles.TopBarGridButton} style={{backgroundColor: testingButton ? "red" : "green"}} onClick={(e) => setAnchorEl(e.currentTarget)}>C</button>
                            
                                <Menu keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                                    {/* <AgoraCameras/> */}
                                    {
                                        
                                        anchorlist.map(device => (
                                            <MenuItem onClick={() => {switchCameraButton(device.deviceId)}}>{device.label}</MenuItem>
                                        ))

                                        
                                    }
                                {/* switchCameraButton(aogra_cameraID) */}
                                </Menu>
                            </div>
                        </div>
                    :
                        ""
                    }
                </div>
                <div className={myStyles.vidoContaner} id="remote-container" onClick={() => stremController()}> 
                    {/* <p style={{color:'white'}}>{`${chat != undefined ? chat.length : "und"}`}</p> */}
                    {/* <video/> */}
                    {/* <MediaPlayer videoTrack={localVideoTrack} audioTrack={localAudioTrack}></MediaPlayer> */}
                </div>

                <div className={myStyles.comments} >
                    { chat != null && Object.entries(chat).length > 0 ?
                        <List className={myStyles.commentsList} id={"commentsList"} header="rw" component="div">
                            {/* chat.map(x => ( */}
                            {Object.entries(chat).map(([key, value]) => {
                                return(
                                    <UserMsg
                                        key={key}
                                        chatId={key} 
                                        obj={value}
                                        isLoggedInAsCord={isLoggedInUserCanDeleteMassages()}
                                        onDelete={() => deleteMassage(id, key)} // ! ON DELETE BUG
                                    />
                                )
                            })}
                            <div style={{backgroundColor:'red'}} ref={el => messagesEndRef.current = el}></div>
                        </List>
                    : 
                        <div>
                            <div>
                                {chat.length == 0 ? <p>لايوجد تعليقات</p> : ""}
                            </div>
                        </div>
                    }
                </div>
                <div className={myStyles.botoomColmn}>
                    <Grid>
                        <div className={myStyles.ChatSendBox}>
                            
                            <Grid item className={myStyles.sendButton}>
                                <Button fullWidth onClick={() => setNewComment(id, massageBox).then((r) => !r.error ? setMassageBox(""): "")}>
                                    ارسال
                                </Button>
                            </Grid>

                            <Grid item className={myStyles.MsgInputDiv}>
                                <Input  
                                    type="text"
                                    value={massageBox}
                                    onChange={(e) => setMassageBox(e.target.value)}
                                    fullWidth
                                    variant="filled"
                                    disableUnderline
                                    margin="dense"
                                    inputProps={{
                                        style:{
                                            height:"100%",
                                            backgroundColor:"white",
                                            textAlign:"right",
                                            justifyContent: 'center',
                                            border:"5px soild black",
                                            paddingRight:"5px",
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item className={myStyles.sendButton}>
                                <div onClick={(e) => debug()} style={{textAlign:'center', alignItems:'center', justifyContent:'center', width:'100%', height:'100%', display:'flex'}}>
                                    {viewersCount === 0 ? "--" : viewersCount}
                                </div>
                            </Grid>
                        </div>
                    </Grid>
                </div>
            </Div100vh>
        </div>
    )
}

export default LiveStramPage;