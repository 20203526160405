// VerifiyPhoneNumberPage.jsx
import {useState, useEffect} from 'react';
import { useStoreState, useStoreActions} from "easy-peasy";
import { useHistory, useParams} from "react-router-dom";
import { toast } from 'react-toastify';
import loadingImg from '../imgs/loading.gif'
// import DoneIcon from '@material-ui/icons/Done';

import MainError from '../components/MainError';

import {
    Input,
    makeStyles,
    TextField,
    InputAdornment,
    Container
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    main:{
        
    },
    phoneLable:{
        textAlign:'right',
        marginBottom:'8px',
        paddingTop:'15px',
        fontSize:'20px'
    },
    phoneInputEle:{
        backgroundColor:'lightGray',
        // border: 'none',
        // padding:'11px',
        borderRadius:'9.2px',

        '&:focus':{
            'outline':'none'
        }
    },
    phoneLableErrorMsg:{
        textAlign:'right',
        fontSize:'14px',
        color:'red'
    },
    formButton:{
        width:'100%',
        backgroundColor:'#3EAE2C',
        height:'3rem',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        color:'white',
        userSelect:'none',
        cursor:'pointer',
        
    }
}))

const VerifiyPhoneNumberPage = () => {
    const myStyles = useStyles();
    const urlHistory = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, seterrorMsg] = useState("");
    const [currentStage, setCurrentStage] = useState(0);
    const [formErrorMsg, setFormErrorMsg] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("")
    const [pinNumber, setPinNumber] = useState("")

    // const getState = useStoreState(state => state.ShowWrongOnlyState)
    // const useFunc = useStoreActions(actions => actions.submitReadingSolution);

    useEffect(() => {
        
    },[])

    const numberInputHandler = (e) => {
        const number = parseInt(e.target.value)

        if (/^[\d]*\.?[\d]{0,2}$/.test(number)){
            setPhoneNumber(number)
        }
        else if(phoneNumber.toString().length == 1){
            setPhoneNumber("")
        }
    }

    const validateNumber = () => {
        const n = phoneNumber.toString()
        setFormErrorMsg("")
        if(n != "" && n.length == 9){
            return true
        }
        else{
            setFormErrorMsg("تحقق من رقم الجوال*")
            return false
        }
    }

    const validatePin = () => {
        const n = pinNumber.toString()
        setFormErrorMsg("")
        if(n != "" && n.length == 8){
            return true
        }
        else{
            setFormErrorMsg("تحقق من الرمز*")
            return false
        }
    }

    const fromButtonHandler = () => {
        if(validateNumber(phoneNumber) && currentStage == 0){
            // * Send the number to server then go to page2
            setCurrentStage(1)
        }
        else if(validatePin(pinNumber) && currentStage == 1) {
            // * Send the pin to server then redirect user to main page 
            setCurrentStage(0)
        }
    }

    if(!isLoading && errorMsg == ""){
        return(
            <Container maxWidth="sm" className={myStyles.main}>
                {currentStage == 0 ?
                    <div className={myStyles.phoneDiv}>
                        <div className={myStyles.phoneLable}>
                            <label htmlFor="phoneNumber">وثق رقم جوالك</label>
                        </div>
                        <div className={myStyles.phoneInput}>
                            <Input 
                                className={myStyles.phoneInputEle} 
                                type="tel"
                                name="phoneNumber" 
                                disableUnderline
                                value={phoneNumber}
                                error
                                fullWidth
                                startAdornment={
                                    <InputAdornment style={{marginLeft:'8px'}} position="start">+966</InputAdornment>
                                }
                                onChange={(e) => numberInputHandler(e)}
                                inputProps={{
                                    
                                    style:{
                                        'margin':'8px'
                                    },
                                    maxLength: 9
                                    
                                }}
                            />
                        </div>

                        <div className={myStyles.phoneLableErrorMsg}>
                            {formErrorMsg}
                        </div>
                    </div>
                    : 
                    <div className={myStyles.pinDiv}>
                        <div className={myStyles.phoneLable}>
                                ادخل رمز التحقق
                        </div>

                        <div className={myStyles.pinInput}>
                            <Input 
                                className={myStyles.phoneInputEle} 
                                type="text"
                                name="pinNumber" 
                                disableUnderline
                                error
                                fullWidth
                                value={pinNumber}
                                onChange={(e) => setPinNumber(e.target.value)}
                                inputProps={{
                                    
                                    style:{
                                        'margin':'8px',
                                        'textAlign':'center',
                                        'letterSpacing':'0.5rem'
                                    },
                                    maxLength: 8
                                    
                                }}
                            />
                        </div>

                        <div className={myStyles.phoneLableErrorMsg}>
                            {formErrorMsg}
                        </div>
                    </div>
                }
                
                <div className={myStyles.formButton} onClick={() => fromButtonHandler()} style={{'marginTop': formErrorMsg.length > 1 ?  '14px' : '30px'}}>
                    {currentStage == 0 ? "ارسال" : "تفعيل"}
                </div>

            </Container>
        )
    }
    else if(!isLoading && errorMsg.length > 0){
        return(
            <div style={{width:'100%', display:'flex', justifyContent:'center', height:'100vh', alignItems:'center'}}>
                <MainError msg="Error"/>
            </div>
        )
    }
    else {
        return(
            <div style={{width:'100%', display:'flex', justifyContent:'center', alignSelf:'center', height:'100vh', alignItems:'center'}}>
                <img src={loadingImg} style={{ width:'200px', height:'200px'}}/> 
            </div>
        )
    }

}

export default VerifiyPhoneNumberPage;