import {action, thunk} from 'easy-peasy';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from 'axios';
import config from '../config.json'
import { getAuctionList } from '../ApiControllers/api';
import { errorPopup } from '../hooks/popup';

// streamInfo:{
//     "StreamiD":"5578411442",
//     "StreamTitle":"test auction",
//     "iSChatOpen":true,
//     "isLive":false,
//     "StartDate":"01/01/2020 3:00 PM",
//     "EndDate":"01/01/2020 4:00 PM",
//     "lastPrice":1000,
//     "Viewers Count":50,
//     "chatServerRoom":"56215615",
//     "LiveStreamUrl":"https://wadada.coawda/411518915619",
//     "StreamInfo":{
//         appID:"",
//         token:"",
//         role:"",
//     },
//     "CordList":[
//         {
//             userId:"554241"
//         }
//     ],
//     "BanList":[
//         {
//             userId:"845215"
//         },
//         {
//             userId:"845215"
//         },
//         {
//             userId:"845215"
//         },
//     ],
// }

// const server = "http://fahadpc.local:83"
// const urlHistory = useHistory();

const setRequestOptions = (token) => {
    return({
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization':`Token ${token}`,
        }
    })
}

export default {
    auctionsList : [],
    canHost      : false,
    chat         : [],
    userRules    : null,
    isVerified   : null,


    setAuctionList: action((state, payload) => {
        if(payload !== true)
            state.auctionsList = payload;
    }),
    setCanHost: action((state, payload) => {
        state.canHost = payload;
    }),
    setChatList: action((state, payload) => {
        state.chat = payload;
    }),
    addOneToChatList: action((state, payload) => {
        state.chat = [...state.chat, payload]
    }),
    deleteChatMsg: action((state, payload) => {
        state.chat.map(msg => {
            if(msg.id === payload.msgID){
                msg.chatActionMsg = "MASSAGE_DELETED"
            }
        })

        state.chat = [...state.chat, payload]
    }),
    setUserRules: action((state, payload) => {
        state.userRules = payload;
    }),
    setisVerified: action((state, payload) => {
        state.isVerified = payload
    }),
    // * Actions
    getAuctionsList: thunk(async (state, payload) => {
        try {
            const auctionList = await getAuctionList()
            if(!auctionList.error){
                state.setAuctionList(auctionList.data.auctionsList);
                state.setCanHost(auctionList.data.canHost)
                state.setisVerified(auctionList.data.isVerified)
                return true
            }
            else{
                // Error
                if (config.Logerrors) {console.error(`in-Error => ${auctionList.req.statusText} (main list)`)}
                // errorPopup("Error #11")
                return false
            }
            
        } catch (error) {
            if (config.Logerrors) {console.error(`out-Error => ${error}`)}
            return false
        }
    }),
    getAuctionsListOLD: thunk(async (state, payload) => {
        try {
            const url   = config.server_url + "/api/v1/get/auctionlist"
            const req   = await fetch(url, setRequestOptions(payload.token))
            const data  = await req.json();
            if(req.status === 200){
                state.setAuctionList(data.auctionsList);
                state.setCanHost(data.canHost)
            }
            
            return req;
        } catch (error) {
            toast.error(`error ${error}`)
            console.log(`error ${error}`);
            return null;
        }
    }),

}