import {
    makeStyles,
    ListItem,
    Chip,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Typography,
} from '@material-ui/core';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import moment from 'moment'
import React from 'react'

const useStyles = makeStyles(() => ({

    userMsg:{
        textAlign:"right",
        // backgroundColor:"red",
    },
    user_name:{
        paddingRight:"15px",
        fontWeight:"bold",
        color:"red",
        paddingLeft:"15px"
    },
    user_Msg:{
        
    },
    user_timeStamp:{
        paddingLeft:"10px"
    },
    MenuItem:{
        textAlign:"center"
    }
}))

/*
    [] if the logged user is orginazer 
    [] 


*/


const UserMsg = (props) => {
    const myStyles = useStyles();
    const deletedMsg = "تم حذف هذه الرسالة"
    const [anchorEl, setAnchorEl] = React.useState(null);
    var obj = props.obj //Object.keys(props.obj)[0]
    var chatId = props.chatId
    var date_created = moment(obj.date_created).format("h:mm")
    

    const handleUserProfileClick = (event) =>{
        if(props.isLoggedInAsCord)
            setAnchorEl(event.currentTarget);
    }
    
    const handleUserProfileClose = () =>{
        setAnchorEl(null);
    }

    return(
        <div className={myStyles.userMsg}>
            {obj.id != null ? 
                <ListItem divider style={{display:'flex', justifyContent:'flex-end'}}>
                    <div className={myStyles.user_Msg}>
                        {!obj.isDeleted ? obj.chatActionMsg : deletedMsg}
                    </div>
                    <div className={myStyles.user_name} onClick={handleUserProfileClick} >
                        : {obj.username}
                    </div>
                    <div>
                        {
                            props.isLoggedInAsCord && props.isLoggedInAsCord != null ? 
                                <Menu
                                    // id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleUserProfileClose}
                                >
                                    <MenuItem onClick={() => {props.onDelete(obj.id); handleUserProfileClose()}} className={myStyles.MenuItem}>حذف</MenuItem>
                                    <MenuItem onClick={handleUserProfileClose}>حظر</MenuItem>
                                </Menu>
                            :
                            ""
                        }
                    </div>
                    <div>
                        {/* {props.isVerified ? <Chip label="موثق" color="primary"/> : ""} */}
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                            {obj.isVerified ? <VerifiedUserIcon style={{marginRight:'8px'}}/> : ""}
                            {obj.isCord ? <Chip label="منظم" color="primary"/> : ""}
                        </div>
                    </div>
                    <div className={myStyles.user_timeStamp}>
                        {date_created}
                    </div>
                </ListItem>
                :
                ""
            }
        </div>
    )
}
export default UserMsg;